<template>
<div>
  <Content>
      <Listagem />
  </Content>
</div>

</template>
<script>
import BaseView from '@/template/BaseView.vue';
import Content from "@/components/LeadAd/Content.vue";
import Listagem from "@/components/LeadAd/Listagem.vue";

export default {
  components: {
    BaseView,
    Content,
    Listagem
  },
  // created() {
  //   if (this.$store.getters.recursos.leadads < 1) {this.$router.push("/dynamicRoute/AccessDenied")};
  //   if (this.$store.getters.user.user.level !== 'admin') {this.$router.push("/dynamicRoute/AccessDenied")};
  // },
};
</script>