<template>
  <div>
    <BaseHeader title="LeadAds" :navigation="navigation">
      <div>
        <BaseButton variant="outline-primary" @click="redirect" class="mr-2">
          Leads capturados
        </BaseButton>
        <BaseButton variant="primary" v-b-modal.modal-add-fanPage>
          Associar Fanpage
        </BaseButton>
      </div>

      <template v-slot:link>
        <span class="text-item"> Listagem de Fanpages </span>
      </template>
    </BaseHeader>
    <ModalAddFanPage idModal="modal-add-fanPage" @salvedPage="salvedPage" />
  </div>
</template>

<script>
import ModalAddFanPage from "./ModalAddFanPage.vue";
import BaseHeader from "@/components/BaseHeader";


export default {
  name: "HeaderIndex",
  components: {
    ModalAddFanPage,
    BaseHeader,
  },
  data: () => ({
  }),
  methods: {
    salvedPage() {
      this.$emit("salvedPage");
    },
    redirect(){
      this.$router.push(`/${this.$store.getters.user.user.tenant_subdomain}/apps/lead-ads/leadgen/`)
    }
  },
  computed: {
    navigation() {
      return [
        { link: "Aplicativos", to: "/dynamicRoute/apps" },
        { link: "LeadAds", to: this.$route.fullPath },
      ]
    },
    route() {
      return 
    }
  },
};
</script>
<style scoped lang="scss">
.btn-historic,
.btn-open-modal-fanpage {
  height: 50px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  border: 0px;
}

.btn-historic > span {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.btn-open-modal-fanpage:active {
  border: 0px;
}
.btn-historic {
  width: 216px;
  background-color: var(--background-color-white-light);
  margin-right: 25px;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}

.btn-open-modal-fanpage {
  width: 209px;
  background-color: var(--background-color-green);
  color: #ffffff;
  border: 0px;
  transition: all 0.3s;
}
</style>
